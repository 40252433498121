@mixin slider-track {
  box-shadow: none;
  background: var(--bg-range-slider-track);
  border: 1px solid var(--color-gray-light);

  @apply w-full h-3 box-border rounded-md cursor-pointer;
}

@mixin slider-thumb {
  box-shadow: 0 0 3px var(--color-gray-dark);
  border: 2.5px solid white;
  margin-top: -4px;
  background: var(--bg-range-slider-thumb);
  -webkit-appearance: none;

  @apply w-4 h-4 cursor-pointer rounded-50 box-border origin-center transition-transform;

  &:hover,
  &:focus {
    transform: scale(1.25);
  }
}

input.console-input[readonly],
input.console-input[disabled] {
  outline: none;
  background-color: #fff !important;
  -webkit-box-shadow: #fff !important;
  -moz-box-shadow: #fff !important;
  color: rgba(88, 88, 88, 0.5) !important;
}

input[type='range'].range-slider {
  --bg-range-slider-thumb: var(--color-primary);
  --bg-range-slider-track: transparent;
  -webkit-appearance: none;

  @apply mt-2 mb-2 w-full h-2 border-0;

  &::-webkit-slider-runnable-track {
    @include slider-track;
  }

  &::-moz-range-track {
    @include slider-track;
  }

  &::-webkit-slider-thumb {
    @include slider-thumb;
  }

  &::-moz-range-thumb {
    @include slider-thumb;
  }
}
