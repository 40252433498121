@import 'normalize';

:root {
  --color-gray-dark: #585858;
  --color-gray-main: #f3f3f3;
  --color-gray-middle: #8f8e8e;
  --color-gray-light: #ebeff2;
  --color-gray-ap-bg: #eaeaea;
  --color-gray-hover: #f8f8f8;
  --color-gray-boxBg: #f1f1f1;
  --color-gray-completed: #f4f4f4;
  --color-gray-light-hover: #fbfbfb;
  --color-gray-highlight: #eeeeee;
  --color-gray-cerebral: #cccccc;
  --color-gray-mercury: #e3e3e3;
  --color-gray-very-light: #f6f6f6;
  --color-gray-border: rgb(222, 222, 222);
  --color-gray-very-light-opacity-50: rgba(241, 241, 241, 0.5);
  --color-gray-very-dark: #232429;
  --color-gray-text: #7d8398;
  --color-gray-info: #b0b5c1;
  --color-blue-bg: #f4f6fa;
  --color-blue-draft: #e9eff6;
  --color-blue-light: #e6f3fb;
  --color-blue-medium: #3c93c8;
  --color-blue-bright: #00b9ff;
  --color-blue-sky: #01b3ff;
  --color-blue-glow: #effaff;
  --color-blue-hover: #6aa3ef;
  --color-blue-venmo: #008cff;
  --color-blue-paypal: #179bd7;
  --color-blue-bar: #1555cf;
  --color-blue-stripe: #635bff;
  --color-blue-cal-bg: #aaccf9;
  --color-blue-paypal-hover: #44b6ea;
  --color-blue-stripe-hover: #8f89ff;
  --color-blue-chipBg: #cfe2fb;
  --color-blue-hard: #210e9a;
  --color-blue-deep: #171f4a;
  --color-purple-medium: #b71eff;
  --color-purple-light: #7777a3;
  --color-orange-main: #ef7456;
  --color-orange-medium: #ff7a00;
  --color-orange-verylight: #fff2e6;
  --color-orange-bright: #dca231;
  --color-orange-darker: #cc8a0b;
  --color-yellow-light: #feffcb;
  --color-yellow-main: #ffcd29;
  --color-red-light: #fbebeb;
  --color-red-verylight: #ffdad2;
  --color-red-moderate: #bd4a4a;
  --color-red-danger: #c64141;
  --color-red-danger-hover: #f26b6b;
  --color-red: #eb5757;
  --color-red-drk: #d9000d;
  --color-red-canceled: #c33535;
  --color-red-cover: #f95656;
  --color-green-darklime: #2c802a;
  --color-green-lame: #e2f5e2;
  --color-green-light: #22d912;
  --color-green-very-light: #3eed2f;
  --color-green-status: #04ce18;
  --color-green-cover: #5dbb5b;
  --color-white-main: #ffffff;
  --color-bdr: #c3cde3;
  --color-white-opacity-6: rgba(255, 255, 255, 0.6); // #ffffff with opacity 0.6
  --color-primary: #2f80ed; // primary color is rewrite from main.template.js and login.page.js files
  --color-primary-opacity-50: rgba(
    47,
    128,
    237,
    0.5
  ); // primary color with opacity 0.5
  --color-primary-opacity-15: rgba(
    47,
    128,
    237,
    0.15
  ); // primary color with opacity 0.15
  --color-transparent: rgba(0, 0, 0, 0);
  --color-black: #000000;
  --color-black-main-opacity-4: rgba(0, 0, 0, 0.4);
  --color-primary-opacity-50: rgba(
    47,
    128,
    237,
    0.5
  ); // primary color with opacity 0.5
  --color-black-secondary: #202040;
  --color-shop-primary: #2f80ed;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Montserrat', sans-serif !important;
}

/* Firefox */
html,
* {
  scrollbar-color: var(--color-primary) var(--color-gray-light);
  scrollbar-width: thin;
  scrollbar-face-color: var(--color-primary);
  scrollbar-track-color: var(--color-gray-light);
}

@media (hover: hover) {
  ::-webkit-scrollbar {
    height: 4px !important;
    width: 4px !important;
    cursor: pointer !important;
  }
  ::-webkit-scrollbar-thumb {
    width: 4px !important;
    border-radius: 10px !important;
    background-color: var(--color-primary) !important;
  }
  ::-webkit-scrollbar-track {
    width: 2px !important;
    border-radius: 10px !important;
    background-color: var(--color-gray-light) !important;
  }
}

.nav-link-active {
  position: relative;
  &:before {
    position: absolute;
    left: -24px;
    content: '';
    width: 2px;
    height: 100%;
    background: var(--color-primary);
  }
  &.link-without-icon {
    &:before {
      left: -58px;
    }
  }
}

.copy-button {
  &:active,
  &:focus {
    @apply text-primary outline-none;
  }
}
.controlled-circle circle {
  stroke-width: var(--circle-stroke-width) !important;
}

@keyframes cool-spin-anti-clockwise {
  0% {
    stroke-dashoffset: 135;
  }
}

@keyframes cool-spin-clockwise {
  0% {
    stroke-dashoffset: 0;
  }
}

.animate-spin-dashboard-once .MuiCircularProgress-circle {
  animation-duration: 2.3s;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-name: cool-spin-anti-clockwise;
  animation-iteration-count: 1;
}

.animate-spin-dashboard-once.clockwise .MuiCircularProgress-circle {
  animation-name: cool-spin-clockwise;
}

.flex-basis-20 {
  flex-basis: 20% !important;
}

.flex-basis-25 {
  flex-basis: 25% !important;
}

.flex-basis-40 {
  flex-basis: 40% !important;
}

.flex-basis-50 {
  flex-basis: 50% !important;
}

.main-table {
  border-spacing: 0 0.5rem;

  @media (min-width: 768px) {
    th,
    td {
      padding: 6px;
    }
  }
}

.main-table td:first-of-type {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.main-table td:last-of-type {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.corder-borders {
  border-width: 4px;
  border-color: currentColor;
  position: relative;
  border-radius: 0 !important;

  > * {
    position: relative;
    z-index: 1;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    background: white;
  }

  &:before {
    top: -5px;
    bottom: -5px;
    width: 70%;
    left: 15%;
  }

  &:after {
    left: -5px;
    right: -5px;
    height: 70%;
    top: 15%;
  }
}

.h-screen {
  height: calc(var(--vh, 1vh) * 100) !important;
}
.max-h-screen {
  max-height: calc(var(--vh, 1vh) * 100) !important;
}
.min-h-screen {
  min-height: calc(var(--vh, 1vh) * 100) !important;
}
.cate-h-screen {
  max-height: calc(100vh - 168px);
}
.height-mobileview {
  max-height: calc(100vh - 250px);
}
.category-height {
  max-height: calc(100vh - 156px);
}
.service-height {
  max-height: calc(100vh - 256px);
}
.max-height-62 {
  max-height: calc(100vh - 280px);
}

//
.text-primary-color {
  color: var(--color-primary);
}

.bg-secondary-color {
  background-color: var(--color-gray-light);
}

@mixin multilineEllipsis($selector: 'multiline-ellipsis', $lines: 3) {
  @for $i from 1 to $lines {
    .#{$selector}-#{$i} {
      display: -webkit-box !important;
      -webkit-box-orient: vertical !important;
      overflow: hidden !important;
      -webkit-line-clamp: $i !important;

      @if $i == 1 {
        overflow-wrap: break-word !important;
        word-break: break-word !important;
      }
    }
  }
}

@layer utilities {
  @variants responsive {
    @include multilineEllipsis();
  }
}

@import 'date-picker';
@import 'checkbox';
@import 'radion-button';
@import 'input';

.backdrop-blur {
  backdrop-filter: blur(2px);
}
.text-14 {
  font-size: 14px;
}
.coustom-lable {
  .bg-blue-light {
    .MuiFormControlLabel-label {
      font-size: 12px;
    }
  }
  @media (max-width: 767px) {
    .MuiFormControlLabel-label {
      font-size: 12px;
    }
  }
  @media (max-width: 390px) {
    .bg-blue-light {
      .MuiFormControlLabel-label {
        font-size: 10px;
      }
    }
  }
}
.react-select__indicator {
  @media (max-width: 767px) {
    padding: 0px !important;
  }
}
.react-select__placeholder {
  @media (max-width: 767px) {
    min-width: 60px;
  }
}
.react-select__value-container {
  @media (max-width: 767px) {
    padding: 2px 4px !important;
  }
}
.react-select__indicator-separator {
  @media (max-width: 767px) {
    display: none;
  }
}
// .react-select__option {
//   max-height: 150px;
// }
