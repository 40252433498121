.containerCheckbox {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  color: var(--color-gray-dark);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.containerCheckbox input {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border: 1px solid var(--color-gray-middle);
  border-radius: 2px;
  box-sizing: border-box;
}

/* When the checkbox is checked, add a blue background */
.containerCheckbox input:checked ~ .checkmark,
.indeterminateContainerCheckbox input ~ .checkmark {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-primary);
  border: 1px solid var(--color-primary);
}

.containerCheckbox input ~ .checkmark svg {
  display: none;
}

.containerCheckbox input:checked ~ .checkmark svg,
.indeterminateContainerCheckbox input ~ .checkmark svg {
  display: block;
}
