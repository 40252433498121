.checkboxToggle {
  display: inline-block;
  padding: 4px 4px 4px 53px;
  margin: 0;
  position: relative;
  cursor: pointer;
}

.checkboxToggle input {
  position: absolute;
  opacity: 0;
  cursor: inherit;
}

.checkboxToggle span {
  display: inline-block;
  font: normal 12px/16px Arial;
  padding: 4px 0;
}

.checkboxToggle span:before,
.checkboxToggle span:after {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.3s;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.checkboxToggle span:before {
  left: 8px;
  height: 18px;
  width: 40px;
  border-radius: 52px;
  background: var(--color-gray-mercury);
}

.checkboxToggle span:after {
  left: 4px;
  height: 24px;
  width: 24px;
  background: var(--color-gray-hover);
  border-radius: 50%;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15), 0 3px 1px rgba(0, 0, 0, 0.06);
}

.checkboxToggle input:checked + span:before {
  background: var(--color-primary);
}

.checkboxToggle input:checked + span:after {
  left: 30px;
}

.checkboxToggle input:disabled + span {
  opacity: 0.35;
}

.checkboxToggle input:disabled + span:before {
  background: var(--color-gray-middle);
}
