.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
  width: 92px;
  height: 32px;
}

.react-datepicker__input-container input {
  border: 1px solid var(--color-gray-light);
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  color: var(--color-gray-dark);
  text-align: center;
}
